(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/banner_layouts/griditem-quickplay/views/item.js') >= 0) return;  svs.modules.push('/components/banner_layouts/griditem-quickplay/views/item.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_svs.banner_layouts=_svs.banner_layouts||{};
_svs.banner_layouts.griditem_quickplay=_svs.banner_layouts.griditem_quickplay||{};
_svs.banner_layouts.griditem_quickplay.templates=_svs.banner_layouts.griditem_quickplay.templates||{};
svs.banner_layouts.griditem_quickplay.templates.item = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = 
  "<div class=\"banner-griditem-quickplay banner-common-griditem f-content"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"branding") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":4,"column":6},"end":{"line":4,"column":105}}})) != null ? stack1 : "")
    + "\"\n  data-impression=\"true\" data-impression-tracking=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"trackingString") || (depth0 != null ? lookupProperty(depth0,"trackingString") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"trackingString","hash":{},"data":data,"loc":{"start":{"line":5,"column":51},"end":{"line":5,"column":69}}}) : helper)))
    + "\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isAdmin") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":71},"end":{"line":6,"column":32}}})) != null ? stack1 : "")
    + ">\n  <div class=\"banner-background-clip\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"landscapeImage") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":4},"end":{"line":12,"column":11}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"portraitImage") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":4},"end":{"line":17,"column":11}}})) != null ? stack1 : "")
    + "  </div>\n"
    + ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"gradientOpacity") : depth0),"!==","0",{"name":"compare","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":2},"end":{"line":21,"column":14}}})) != null ? stack1 : "")
    + "  <div class=\"banner-main-content banner-main-padding-0\">\n    <div class=\"banner-main-text "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasBlackText") : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.program(16, data, 0),"data":data,"loc":{"start":{"line":23,"column":33},"end":{"line":23,"column":84}}})) != null ? stack1 : "")
    + "\">\n      "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"splashColor") : depth0),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":6},"end":{"line":24,"column":94}}})) != null ? stack1 : "")
    + "\n      <div class=\"banner-text-large notranslate\">"
    + alias4((lookupProperty(helpers,"formatCurrency")||(depth0 && lookupProperty(depth0,"formatCurrency"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"systemCost") : depth0),{"name":"formatCurrency","hash":{},"data":data,"loc":{"start":{"line":25,"column":49},"end":{"line":25,"column":78}}}))
    + " kr</div>\n      <div class=\"banner-text-small\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"rows") || (depth0 != null ? lookupProperty(depth0,"rows") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"rows","hash":{},"data":data,"loc":{"start":{"line":26,"column":37},"end":{"line":26,"column":45}}}) : helper)))
    + " chanser att vinna</div>\n    </div>\n    <div class=\"banner-main-pix fc-white\">\n";
  stack1 = ((helper = (helper = lookupProperty(helpers,"isMobile") || (depth0 != null ? lookupProperty(depth0,"isMobile") : depth0)) != null ? helper : alias2),(options={"name":"isMobile","hash":{},"fn":container.program(20, data, 0),"inverse":container.program(22, data, 0),"data":data,"loc":{"start":{"line":29,"column":6},"end":{"line":45,"column":19}}}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"isMobile")) { stack1 = container.hooks.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "      "
    + ((stack1 = (lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||alias2).call(alias1,"button",{"name":"ui","hash":{"class":"banner-main-button","size":"300"},"fn":container.program(24, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":46,"column":6},"end":{"line":46,"column":70}}})) != null ? stack1 : "")
    + "\n    </div>\n  </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"branding") : depth0),{"name":"if","hash":{},"fn":container.program(26, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":49,"column":2},"end":{"line":56,"column":9}}})) != null ? stack1 : "")
    + "</div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " banner-griditem-branding-"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"branding") || (depth0 != null ? lookupProperty(depth0,"branding") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"branding","hash":{},"data":data,"loc":{"start":{"line":4,"column":49},"end":{"line":4,"column":61}}}) : helper)));
},"4":function(container,depth0,helpers,partials,data) {
    return " banner-griditem-no-branding";
},"6":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " data-cmsid=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"bannerId") || (depth0 != null ? lookupProperty(depth0,"bannerId") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"bannerId","hash":{},"data":data,"loc":{"start":{"line":5,"column":100},"end":{"line":5,"column":112}}}) : helper)))
    + "\"\n  data-cmstype=\"banner\" ";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"banner-background-landscape\">\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"components-content-image_v2-image"),(depth0 != null ? lookupProperty(depth0,"landscapeImage") : depth0),{"name":"components-content-image_v2-image","data":data,"indent":"      ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </div>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"banner-background-portrait\">\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"components-content-image_v2-image"),(depth0 != null ? lookupProperty(depth0,"portraitImage") : depth0),{"name":"components-content-image_v2-image","data":data,"indent":"      ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </div>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <div class=\"banner-griditem-gradient banner-griditem-gradient-"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"gradientOpacity") || (depth0 != null ? lookupProperty(depth0,"gradientOpacity") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"gradientOpacity","hash":{},"data":data,"loc":{"start":{"line":20,"column":64},"end":{"line":20,"column":83}}}) : helper)))
    + "\"></div>\n";
},"14":function(container,depth0,helpers,partials,data) {
    return "fc-black";
},"16":function(container,depth0,helpers,partials,data) {
    return "fc-white";
},"18":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"banner-main-text-splash bg-"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"splashColor") || (depth0 != null ? lookupProperty(depth0,"splashColor") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"splashColor","hash":{},"data":data,"loc":{"start":{"line":24,"column":64},"end":{"line":24,"column":79}}}) : helper)))
    + "\"></div>";
},"20":function(container,depth0,helpers,partials,data) {
    return "      <div class=\"banner-pix-container banner-pix-two-lines\">\n        <div>\n          <span class=\"f-bold\">PIX!</span>\n          <span class=\"f-normal\">Färdiga&nbsp;spel.</span>\n        </div>\n        <div>\n          <span class=\"f-normal\">Helt&nbsp;enkelt.</span>\n        </div>\n      </div>\n";
},"22":function(container,depth0,helpers,partials,data) {
    return "      <div class=\"banner-pix-container banner-pix-one-line\">\n        <span class=\"f-bold\">PIX!</span>\n        <span class=\"f-normal\">Färdiga&nbsp;spel.</span>\n        <span class=\"f-normal\">Helt&nbsp;enkelt.</span>\n      </div>\n";
},"24":function(container,depth0,helpers,partials,data) {
    return "Köp";
},"26":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <div class=\"banner-header\">\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"components-lb_ui-product_header-product_header"),depth0,{"name":"components-lb_ui-product_header-product_header","hash":{"class":"banner-header-tablet","heightMode":"tablet","branding":(depth0 != null ? lookupProperty(depth0,"branding") : depth0),"emblem":"true"},"data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(lookupProperty(partials,"components-lb_ui-product_header-product_header"),depth0,{"name":"components-lb_ui-product_header-product_header","hash":{"class":"banner-header-desktop","heightMode":"desktop","branding":(depth0 != null ? lookupProperty(depth0,"branding") : depth0),"emblem":"true"},"data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "  </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"components-banner_actions-quickplay-quickplay"),depth0,{"name":"components-banner_actions-quickplay-quickplay","hash":{"crm":(depth0 != null ? lookupProperty(depth0,"crm") : depth0),"clickTrackingString":(depth0 != null ? lookupProperty(depth0,"trackingString") : depth0),"systemCost":(depth0 != null ? lookupProperty(depth0,"systemCost") : depth0),"drawNumber":(depth0 != null ? lookupProperty(depth0,"drawNumber") : depth0),"rowPrice":(depth0 != null ? lookupProperty(depth0,"rowPrice") : depth0),"productId":(depth0 != null ? lookupProperty(depth0,"productId") : depth0),"branding":(depth0 != null ? lookupProperty(depth0,"branding") : depth0)},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"usePartial":true,"useData":true});
Handlebars.partials['components-banner_layouts-griditem_quickplay-item'] = svs.banner_layouts.griditem_quickplay.templates.item;
})(svs, Handlebars);


 })(window);